import { Browser, OpenOptions } from '@capacitor/browser'

type EventNameType = 'browserFinished'

interface ICapacitorBrowser {
  openBrowserWith: (url: string, windowName: string) => Promise<void>
  addListener: (eventName: EventNameType, listenerFunc: () => void) => void
  close: () => void
}

export default class CapacitorBrowser implements ICapacitorBrowser {
  openBrowserWith (url: string, windowName = '_blank') {
    return Browser.open({ url, windowName } as OpenOptions)
  }

  addListener (eventName: EventNameType, listenerFunc: () => void) {
    Browser.addListener(eventName, listenerFunc)
  }

  close () {
    Browser.close()
  }
}
